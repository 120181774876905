<script>
	import config from './config.json';
	import { createEventDispatcher } from 'svelte';
	export var controlsWidth;

	export const clearCanvas = () => {
		dirty = false;
		message = '';
		canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
		preview.getContext('2d').clearRect(0, 0, preview.width, preview.height);
	}

	var canvas, preview, selected, brush;
	var message = '';
	var rotate90 = false;
	var dirty = false;

	const factor = config.canvas.size.factors.drawing;

	const dispatch = createEventDispatcher();

	// select first color in palette
	brush = config.palette.colors[Object.keys(config.palette.colors)[0]]

	function setBrush(ev)
	{
		brush = ev.currentTarget.dataset.color;
		selected.style.left = ev.currentTarget.offsetLeft + 'px';
		selected.style.top = ev.currentTarget.offsetTop +'px';

		// activate rotate90 class to turn the pen emoji eraser-down for the transparency brush
		rotate90 = !ev.currentTarget.dataset.color;
	}

	function canvasMouseMove(ev)
	{
		if (ev.buttons === 1)
			draw(ev);
	}

	function canvasClick(ev)
	{
		draw(ev);
	}

	function draw(ev)
	{
		// the following lines MUST remain within draw() to accomodate for post-scroll drawing
		const rect = canvas.getBoundingClientRect();
		const ctx = canvas.getContext('2d');
		const ptx = preview.getContext('2d');

		const x = Math.floor((ev.clientX - rect.x) / factor);
		const y = Math.floor((ev.clientY - rect.y) / factor);

		if (brush)
		{
			ctx.fillStyle = ptx.fillStyle = brush;
			ctx.fillRect(x * factor, y * factor, factor, factor);
			ptx.fillRect(x, y, 1, 1);

			dirty = true;
		}
		else
		{
			ctx.clearRect(x * factor, y * factor, factor, factor);
			ptx.clearRect(x, y, 1, 1);
		}
	}

	function save()
	{
		dispatch('save', {
			'dirty': dirty,
			'message': message,
			'image': preview.toDataURL('image/png', 1.0)
		});
	}
</script>

<div style="
	--checkerboard-size: {config.canvas.size.x / (config.canvas.size.x / factor * 2)}px;
	--controls-width: {controlsWidth}px;
	--palette-colors-size: {config.palette.size}px;
	--background-color: {config.canvas.background};
">
	<div id="main">
		<div id="canvas-controls">
			<fieldset id="palette-fieldset">
				<div id="selected" bind:this={selected} class:rotate90></div>
				<ul id="palette">
					{#each config.palette.colors as color}
						<li on:click="{setBrush}"
							data-color="{color}"
							style="background-color: {color}"
						></li>
					{/each}
				</ul>
			</fieldset>

			<fieldset id="preview-fieldset">
				<canvas
					id="preview"
					bind:this={preview}
					width="{config.canvas.size.x}"
					height="{config.canvas.size.y}"
				/>
			</fieldset>

			{#if config.message.enabled}
			<fieldset id="message-fieldset">
				<textarea
					bind:value={message}
					maxlength="{config.message.length}"
					placeholder="optional: add a short message to your image."
				></textarea>
				<div>
					<span>{message.length}</span> / {config.message.length}
				</div>
			</fieldset>
			{/if}

			<fieldset id="save-fieldset">
				<p>Once you <strong>Save</strong>, you'll be able to choose a permanent place for your drawing.</p>
				<button class="uk-button uk-button-primary uk-button-small" type="button" on:click={save}>Save</button>
			</fieldset>
		</div>

		<canvas id="canvas"
			class="checkerboard"
			bind:this={canvas}
			on:mousemove={canvasMouseMove}
			on:click={canvasClick}
			width="{config.canvas.size.x * config.canvas.size.factors.drawing}"
			height="{config.canvas.size.y * config.canvas.size.factors.drawing}"
		/>
	</div>
</div>

<style>
	#main {
		display: flex;
	}

	#canvas-controls {
		width: var(--controls-width);
		margin-right: 10px;
	}

	#canvas-controls > fieldset {
		position: relative;
		margin: 0 0 20px;
		padding: 10px;
		border: 1px solid #ccc;
	}
	#canvas-controls > fieldset:before {
		position: absolute;
		padding: 0 3px;
		background-color: #fff;
		top: -.8em;
		left: 7px;
		line-height: normal;
	}

	#palette-fieldset:before {content: "Palette"}
	#preview-fieldset:before {content: "Preview"}
	#message-fieldset:before {content: "Message"}
	#save-fieldset:before {content: "Save"}

	#palette {
		padding: 0;
		margin: 0;
		line-height: 0;
	}
	#palette > li {
		display: inline-block;
		width: var(--palette-colors-size);
		height: var(--palette-colors-size);
		cursor: pointer;
	}

	#selected {
		position: absolute;
		width: var(--palette-colors-size);
		height: var(--palette-colors-size);
		line-height: var(--palette-colors-size);
		left: 10px;
		top: 10px;
		text-align: center;
		overflow: hidden;
	}
	#selected:before {
		content: "✏️";
	}
	.rotate90 {
		transform: rotate(90deg);
	}

	#preview {
		background-color: var(--background-color);
		border: 4px solid var(--background-color);
	}

	textarea {
		width: 100%;
		min-height: 7em;
	}

	#canvas {
		cursor: crosshair;
	}

	.checkerboard, #palette > li:not([data-color]) {
		background-color: #fff;
		background-image:
			linear-gradient(45deg, #ccc 25%, transparent 25%), 
			linear-gradient(135deg, #ccc 25%, transparent 25%),
			linear-gradient(45deg, transparent 75%, #ccc 75%),
			linear-gradient(135deg, transparent 75%, #ccc 75%);
		background-size:
			calc(2 * var(--checkerboard-size)) calc(2 * var(--checkerboard-size)); 
		background-position:
			0 0,
			var(--checkerboard-size) 0,
			var(--checkerboard-size) calc(-1 * var(--checkerboard-size)),
			0 calc(-1 * var(--checkerboard-size));
	}
</style>