<script>
	import config from './config.json';
	export let data;
	var container, x, y, left;
	const width = config.canvas.size.x * config.canvas.size.factors.display;
	const height = config.canvas.size.y * config.canvas.size.factors.display;

	 // determine whether to display container left or right of image
	$: left = data.imgx + (config.canvas.size.x/2) > data.ow / 2;

	// container position calculation
	$: x = data.show? data.imgx + (left? -width -20 -20 : +config.canvas.size.x +20) : -999999;
	$: y = data.show? Math.min(data.oh -height -20, Math.max(0, data.imgy + (config.canvas.size.y/2) -10 -(height/2))): -999999;
</script>

<div bind:this={container} style="left: {x}px; top: {y}px; opacity: {data.show? 1 : 0};
--image-size: {config.canvas.size.x * config.canvas.size.factors.display}px;
--container-width: {width}px;
--container-height: {height}px;
--background-color: {config.canvas.background};
">
	<img src="{data.img}" alt="" />
		<cite>{data.msg}</cite>
</div>

<style>
	div {
		position: absolute;
		width: var(--container-width);
		min-height: var(--container-height);
		overflow:hidden;
		background-color: var(--background-color);
		padding: 10px;
		box-shadow: 0 0 10px #000;
		z-index: 3;
		transition: .1s opacity;
	}

	img {
		width: var(--image-size);
		image-rendering: crisp-edges;
	}

	cite {
		color: #fff;
		word-break: break-word;
	}
</style>