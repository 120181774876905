<script>
	import config from './config.json';
    import Canvas from './Canvas.svelte';
    import Detail from './Detail.svelte';
	import { onMount } from 'svelte';

	var overview, modal, preview, placing_data, detailsContainer;
	var detailsData = {show: false, img: null, msg: '', imgx: 0, imgy: 0, ow: 0, oh: 0};
	var guide = "";
	var csrfToken = '0';
	var placing = false; // preview placing state
	var clearCanvas; // Canvas component child function to clear input after submit
	const username = 'dogpixels';

	// controls width to be whatever is larger, a row of five palette colors or the preview canvas
	const controlsWidth = Math.max(config.palette.size * config.palette.columns, config.canvas.size.x) + 22;

	// main canvas size
	const canvasWidth = config.canvas.size.x * config.canvas.size.factors.drawing;

	onMount(async () => {
		detailsData.ow = overview.clientWidth;
		detailsData.oh = overview.clientHeight;
		
		guide = "Loading...";

		const files = await (await fetch(`${config.api}/${username}`, {method: 'GET'})).json();

		if (!Array.isArray(files))
		{
			guide = "❌ Oh no, an error occurred loading the guestbook entries. Please report to the owner of this page.";
			return;
		}

		let i = 0;

		const getting = setInterval(() => {
			if (i >= files.length)
			{
				clearInterval(getting);
				guide = '';
				return;
			}

			const entry = files[i++];

			let img = document.createElement('img');
			img.src = `${config.files}/${username}/${entry.file}.png`;
			img.alt = entry.m;
			img.style.left = `${entry.x}px`;
			img.style.top = `${entry.y}px`;

			img.addEventListener('click', (ev) => {
				// prevent the click event on #overview
				ev.stopPropagation();

				if (placing)
					return;

				detailsData.img = img.src;
				detailsData.msg = entry.m;
				detailsData.show = true;
				detailsData.imgx = ev.target.offsetLeft;
				detailsData.imgy = ev.target.offsetTop;
			});

			overview.appendChild(img);

			guide = `Loading... ${i} of ${files.length}`;
		}, 100);
	});

	function canvasSave(ev)
	{
		UIkit.modal(modal).hide();
		
		if (ev.detail.dirty)
		{
			placing_data = {
				message: ev.detail.message,
				image: ev.detail.image
			}

			preview.src = placing_data.image;

			placing = true;
			detailsData.show = false;

			guide = 'Choose a nice place for your artwork and click to eternalize yourself.';
		}
	}

	function canvasMouseMove(ev)
	{
		if (!placing)
			return;
		
		preview.style.left = ev.pageX - overview.offsetLeft + 'px';
		preview.style.top = ev.pageY - overview.offsetTop + 'px';
	}
	
	async function canvasClick(ev)
	{
		if (!placing)
		{
			detailsData.show = false;
			return;
		}
		
		placing = false;
		preview.style.opacity = 1;

		guide = "Transmitting...";

		const response = await fetch(`${config.api}/${username}`, {
			method: 'PUT',
			body: JSON.stringify({
				t: csrfToken,
				v: config.version,
				x: preview.offsetLeft,
				y: preview.offsetTop,
				m: placing_data.message,
				i: placing_data.image
			}),
			headers: {
				'Content-Type': 'application/json'
			}
		});

		console.info('gfxgb-api put response:', response);

		if (response.ok)
		{
			guide = '✅ Done. Thank you for your pixels!';
			clearCanvas();

			let payload = response.json();

			if (payload.review)
			{
				guide += ' Your entry will be manually reviewed and enabled.'
			}
		}
		else {
			UIkit.modal.alert(`<h3>Oh no :(</h3><p>The following error(s) were encountered:</p><pre>${await response.json()}</pre>`);
		}
	}
</script>

<div style="
	--background-color: {config.canvas.background};
">
	<div bind:this={modal} id="modal" uk-modal>
		<div class="uk-modal-dialog uk-modal-body" style="width: {controlsWidth + canvasWidth + 10 + 60}px">
			<Canvas
				on:save={canvasSave}
				bind:clearCanvas={clearCanvas}
				controlsWidth={controlsWidth}
			/>
		</div>
	</div>

	<div id="overview" bind:this={overview} on:mousemove={canvasMouseMove} on:click={canvasClick}>
		<img
			alt="preview"
			id="preview"
			class:placing
			bind:this={preview}
			width="{config.canvas.size.x}"
			height="{config.canvas.size.y}"
		/>
		<Detail bind:this={detailsContainer} bind:data={detailsData} />
	</div>
	
	<div id="toolbar">
		<!-- svelte-ignore missing-declaration -->
		<button type="button" class="uk-visible@l" on:click={UIkit.modal(modal).show()}>Draw &amp; Add an Image 🎨</button>
		<span>{guide}</span>
	</div>
</div>

<style>
	#overview {
		position: relative;
		min-height: 480px;
		background-color: var(--background-color);
		box-shadow: inset 0 0 10px #000;
		z-index: 1;
	}
	#overview > :global(img) {
		position: absolute;
		cursor: pointer;
	}
	#preview {
		z-index: 2;
		left: -100%;
		opacity: 0;
	}
	#preview.placing {
		opacity: 1;
	}
	#preview {
		opacity: 0;
	}
	#preview.placing {
		opacity: 1;
	}
	#toolbar {
		padding: 8px 0;
	}
	#toolbar > * {
		padding: 4px;
	}
	#toolbar > button {
		border: none;
		box-shadow: 0 0 10px #000;
		border-radius: 4px;
		cursor: pointer;
	}
	#toolbar > button {
		background-color: #80ff00;
	}
	#toolbar > button:hover {
		background-color: #00cf00;
	}
</style>